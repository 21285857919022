<template>
  <div class="emojiContainer">
    <img v-if="!emojis" :src="insert" class="emojiImage">
  </div>
</template>

<style scoped>
.emojiContainer {
  display: inline;
  height:1em;
}
.emojiImage {
  height:1em;
  position:relative;
  margin: 0 .05em 0 .1em;
  vertical-align: -0.15em;
}
</style>


<script>

export default {
name: 'Emoji',
props: ['name'],
data: function() {
  return {
    insert: "",
    emojis: false,
  }
},
methods: {


},
beforeMount() {

  this.insert = "img/emojis/" + this.name + ".svg";

}
}
</script>












<!--  -->
