<template>
  <div id="app">

    <link href="https://fonts.googleapis.com/css2?family=Roboto&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap" rel="stylesheet">

    <v-app-bar v-if="!isIndex" color="#383838" style="box-shadow: none;" dark>

      <v-spacer v-if="$vuetify.breakpoint.xs"></v-spacer>
      <div class="d-flex">
        <h2><router-link class="domainName" style="color:#fff;font-family: 'M PLUS Rounded 1c', sans-serif;" to="/"><Emoji name='russiaflag'/> LearnRussianCases</router-link></h2>
      </div>

      <v-spacer v-if="$vuetify.breakpoint.xs"></v-spacer>
      <v-spacer v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"></v-spacer>

      <!-- <h3 v-if="signedIn" class="hideOnMobile">Hello, {{ username }} &nbsp;</h3> -->

      <v-img v-if="!signedIn"
      class="googleButton hideOnMobile"
      max-width='191'
      max-height='46'
      src="~@/assets/googlesigninbutton.png"
      @click="googleSignIn"></v-img>

      <v-btn class="hideOnMobile" v-if="signedIn" color="#dedede" style="color:#000" @click="googleSignOut">Sign Out</v-btn>

    </v-app-bar>

    <transition name="fade" mode="out-in">
      <router-view :uid='this.uid' :username='this.username' :userData='this.userData'></router-view>
    </transition>

  </div>
</template>

<script>
import {db} from './firebase/db';
import firebase from 'firebase';
  import Emoji from '@/components/Emoji.vue'

export default {
  components: {
      Emoji
    },
  data() {
    return {
      uid: "",
      displayName: "",
      signedIn: false,
      username: "test",
      userTemp: [],
      userData: []
    }
  },
  methods: {
    async googleSignIn() {
      const provider = new firebase.auth.GoogleAuthProvider();
      await firebase.auth().signInWithPopup(provider).then(() => {

        firebase.auth().onAuthStateChanged((user) => {
          if (user) {

            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            this.uid = user.uid;
            this.displayName = user.displayName;
            this.email = user.email;
            this.emailVerified = user.emailVerified;
            this.signedIn = true;
            this.username = user.displayName;

          } else {
            // User is signed out
            // ...
            this.signedIn = false;
          }
        });

      }).catch(err => {
        console.log(err);
      });

      console.log("uid: " + this.uid);


      var userData = await db.collection('Users').doc(this.uid).get();
      userData = userData.data();
      if (userData == null) {
        console.log("New user");

        await db.collection("Users").doc(this.uid).set({
          username: this.username,
          timeZone: 0,
          totalStreak: 0,
          weeklyStreak: [0, 0, 0, 0, 0, 0, 0],

          lessonsCompleted: [0, 0, 0, 0, 0, 0],
          lessonsCompletedAdjectives: [0, 0, 0, 0, 0, 0],
          //decayFactor, lastReset, resetCounter ...
          //for each ^, mascNoun, mascAdj, femNoun, femAdj, neutNoun, neutAdj, pluAdj
          nomForgettingCurve: [-999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0],
          accForgettingCurve: [-999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0],
          prepForgettingCurve: [-999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0],
          genForgettingCurve: [-999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0],
          datForgettingCurve: [-999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0],
          instForgettingCurve: [-999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0],
          identifyGenderForgettingCurve: [-999, -999, 0],

          nomMascNounPastPerformance: [],
          nomMascAdjPastPerformance: [],
          nomFemNounPastPerformance: [],
          nomFemAdjPastPerformance: [],
          nomNeutNounPastPerformance: [],
          nomNeutAdjPastPerformance: [],
          nomPluAdjPastPerformance: [],

          accMascNounPastPerformance: [],
          accMascAdjPastPerformance: [],
          accFemNounPastPerformance: [],
          accFemAdjPastPerformance: [],
          accNeutNounPastPerformance: [],
          accNeutAdjPastPerformance: [],
          accPluAdjPastPerformance: [],

          prepMascNounPastPerformance: [],
          prepMascAdjPastPerformance: [],
          prepFemNounPastPerformance: [],
          prepFemAdjPastPerformance: [],
          prepNeutNounPastPerformance: [],
          prepNeutAdjPastPerformance: [],
          prepPluAdjPastPerformance: [],

          genMascNounPastPerformance: [],
          genMascAdjPastPerformance: [],
          genFemNounPastPerformance: [],
          genFemAdjPastPerformance: [],
          genNeutNounPastPerformance: [],
          genNeutAdjPastPerformance: [],
          genPluAdjPastPerformance: [],

          datMascNounPastPerformance: [],
          datMascAdjPastPerformance: [],
          datFemNounPastPerformance: [],
          datFemAdjPastPerformance: [],
          datNeutNounPastPerformance: [],
          datNeutAdjPastPerformance: [],
          datPluAdjPastPerformance: [],

          instMascNounPastPerformance: [],
          instMascAdjPastPerformance: [],
          instFemNounPastPerformance: [],
          instFemAdjPastPerformance: [],
          instNeutNounPastPerformance: [],
          instNeutAdjPastPerformance: [],
          instPluAdjPastPerformance: [],

          identifyGenderPastPerformance: []


        })


      } else {
        this.userData = userData;
        console.log(userData);
      }

    },
    googleSignOut(){
      firebase.auth().signOut().then(() => {
        // alert("Signed Out!");
        this.$router.push('/');
        console.log("Signed out")
      }).catch((error) => {
        console.log(error);
      });

      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          this.uid = user.uid;
          this.displayName = user.displayName;
          this.email = user.email;
          this.photoURL = user.photoURL;
          this.emailVerified = user.emailVerified;
          this.signedIn = true;
        } else {
          // User is signed out
          // ...
          this.signedIn = false;
        }
      });
    }
  },
  beforeMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        this.uid = user.uid;
        this.displayName = user.displayName;
        this.email = user.email;
        this.emailVerified = user.emailVerified;
        this.signedIn = true;

        this.username = user.displayName;

      } else {
        // User is signed out
        // ...
        this.signedIn = false;
      }
    });
  },
  computed: {
    isIndex() {
      // console.log("route: " + this.$route.name);
      return this.$route.name == 'Index'
    }
  },
  firestore: {
    // Users: db.collection("Users")
  }
}
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@500&display=swap');

#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: 'Roboto Slab', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

div.v-application--wrap {
    min-height:calc(100vh - 64px) !important;
  }

.v-btn {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

.v-app-bar {
  padding: 0 60px 0 60px;
}

#nav {
  padding: 10px 10px 20px 10px;

  a {
    font-weight: bold;
    color: #2c3e50;
    padding: 0 10px;

    &.router-link-exact-active {
      color: #fca311;
      background: #383838;
      padding: 8px 14px 10px 14px;
      border: 1px solid #383838;
      border-radius: 30px;
    }
  }

  #backToAdmin {
    color: #000;
    // color: #fca311;
    // background: #383838;
    padding: 8px 14px 10px 14px;
    border: 1px solid #000;
    border-radius: 30px;
  }
}
</style>

<style media="screen">
  a {
    color: white;
    text-decoration: none;
  }

  .fade-enter {
  opacity: 0;
  }

  .fade-enter-active {
  transition: opacity 0.2s ease;
  }

  .fade-leave {}

  .fade-leave-active {
  transition: opacity 0.2s ease;
  opacity: 0;
  }
</style>



<style scoped>
  @media screen and (max-width: 1024px) {

    .v-app-bar {
      padding: 0;
      align-items: center;
      align-content: center;
      align-self: center;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
      display: block;
    }

    .v-toolbar__content, .v-toolbar__extension {
      padding: 0;
      position: relative;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    .hideOnMobile {
      display: none;
    }



  }
</style>



<style scoped>
  @media screen and (max-width: 1024px) {

    .domainHeaderMobile {
      color: #dedede;
      font-family: 'M PLUS Rounded 1c', sans-serif;
    }

  }
</style>















<!--  -->
