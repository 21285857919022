import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import {firestorePlugin} from 'vuefire';
import router from './router'
import VuePageTitle from 'vue-page-title'

Vue.use(VuePageTitle, {
  prefix: 'Learn Russian Cases | ',
  // suffix: '- My App '
})

Vue.use(firestorePlugin);

Vue.config.productionTip = false





import VueSimpleMarkdown from 'vue-simple-markdown'
// You need a specific loader for CSS files like https://github.com/webpack/css-loader
import 'vue-simple-markdown/dist/vue-simple-markdown.css'

Vue.use(VueSimpleMarkdown)




new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
