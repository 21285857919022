import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../components/Index.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../components/Dashboard.vue')
  },
  {
    path: '/reference',
    name: 'Reference',
    component: () => import('../components/Reference.vue')
  },
  {
    path: '/lesson',
    name: 'Lesson',
    component: () => import('../components/Lesson.vue'),
    props: true
  },
  {
    path: '/editlessons',
    name: 'EditLessons',
    component: () => import('../components/EditLessons.vue'),
    props: true
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../components/About.vue')
  },
  {
    path: '/practice',
    name: 'Practice',
    component: () => import('../components/Practice.vue'),
    props: true
  },
  {
    path: '/results',
    name: 'Results',
    component: () => import('../components/Results.vue'),
    props: true
  },
  {
    path: '/nouns',
    name: 'Nouns',
    component: () => import('../components/Nouns.vue')
  },
  {
    path: '/adjectives',
    name: 'Adjectives',
    component: () => import('../components/Adjectives.vue')
  },
  {
    path: '/sentencesnouns',
    name: 'SentencesNouns',
    component: () => import('../components/SentencesNouns.vue')
  },
  {
    path: '/sentencesadjectives',
    name: 'SentencesAdjectives',
    component: () => import('../components/SentencesAdjectives.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../components/Admin.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../components/Settings.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
