import firebase from 'firebase';
import 'firebase/firestore';

// Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  var firebaseConfig = {
    apiKey: "AIzaSyA2D3y1qSrBZn9rwbwyNVS7bhBbBtWRcoc",
    authDomain: "learn-russian-cases.firebaseapp.com",
    databaseURL: "https://learn-russian-cases-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "learn-russian-cases",
    storageBucket: "learn-russian-cases.appspot.com",
    messagingSenderId: "725847320395",
    appId: "1:725847320395:web:d40261ea0325ef8a426ace",
    measurementId: "G-BJCWD14EWV"
  };
  // Initialize Firebase
  export const db = firebase.initializeApp(firebaseConfig).firestore();
  firebase.analytics();
